<template>
    <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible">
        <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            @keyup.enter.native="dataFormSubmit()"
            label-width="120px"
            :disabled="type === 'view'"
            label-position="top"
        >
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="主题" prop="title">
                        <el-input v-model="dataForm.title" placeholder=""></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="电话" prop="phone">
                        <el-input v-model="dataForm.phone" placeholder=""></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="设备名称" prop="device">
                        <el-select v-model="dataForm.device" placeholder="设备名称" @change="devNumChange">
                            <el-option
                                v-for="item in deviceList"
                                :label="item.name"
                                :key="item.key"
                                :value="item.name"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="掉线时间" prop="offline">
                        <el-date-picker
                            v-model="dataForm.offline"
                            align="right"
                            type="datetime"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="短信id" prop="bizid">
                        <el-input v-model="dataForm.bizid" placeholder="短信id"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="短信状态" prop="success">
                        <el-select v-model="dataForm.success" placeholder="短信状态">
                            <el-option v-for="item in duanxin" :label="item.label" :value="item.code" :key="item.key" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {cloneDeep} from 'lodash';

const duanxin = [
    {key: 0, label: '成功', code: '0'},
    {key: 0, label: '未成功', code: 'unknown'},
];
export default {
    data() {
        return {
            visible: false,
            type: '',
            deviceList: [],
            duanxin,
            dataForm: {
                id: 0,
                title: '',
                phone: '',
                device: '',
                cabinet: '',
                devNum: '',
                offline: '',
                bizid: '',
                flag: '',
                success: '',
            },
            dataRule: {
                title: [{required: true, message: '不能为空', trigger: 'blur'}],
                cabinet: [{required: true, message: '不能为空', trigger: 'blur'}],
                device: [{required: true, message: '不能为空', trigger: 'blur'}],
                phone: [{required: true, message: '不能为空', trigger: 'blur'}],
            },
        };
    },
    created() {
        this.getDiviceList();
    },
    methods: {
        init(item) {
            this.dataForm.id = item.id || 0;
            this.visible = true;
            this.type = item.type;
            this.$nextTick(() => {
                this.$refs['dataForm'].resetFields();
                if (this.dataForm.id) {
                    this.$client.gainByIdMsg(this.dataForm.id).then((data) => {
                        this.dataForm = data.data;
                    });
                }
            });
        },
        async getDiviceList() {
            let res = await this.$client.gainListDevice();
            this.deviceList = res.data;
        },
        devNumChange(sel) {
            debugger;
            let device = this.deviceList.filter((i) => i.name === sel)[0];
            this.dataForm.devNum = device.key;
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    let funcs;
                    let data = cloneDeep(this.dataForm);
                    if (!data.id) {
                        funcs = this.$client.saveDeviceMsg;
                        delete data['id'];
                    } else {
                        funcs = this.$client.updDeviceMsg;
                    }
                    funcs(data).then((data) => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.visible = false;
                        this.$emit('refreshDataList');
                    });
                }
            });
        },
    },
};
</script>
